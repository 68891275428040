import React from 'react';
import './home.css';
function Home() {
  return (
    <div className='homePage'>
        <div style={{paddingLeft:'40%', paddingTop:'8%'}}>
            <h3 style={{color:'#f3993c', fontWeight:'bold'}}>Innovative Robotics for <br/> Seamless Automation</h3>
       </div>
    </div>
  )
}

export default Home
