import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
      });
    
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_uatv95s', 'template_zfyqgxv', form.current, {
          publicKey: 'jAXWe5LZX_SljvIQt',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            setIsSubmitted(true);  // Show thank you message
            setFormData({ name: '', email: '', message: '' }); 
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
  
    return (
        
        <div>
              {isSubmitted ? (
                        <div className="thank-you-message">
                        <h2 style={{fontWeight:'bold'}}>Thank you for contacting us!</h2>
                        <p style={{fontWeight:'bold'}}>We will get back to you shortly.</p>
                        </div>)
: 
(<section className="common-section contact-section pt-5 text-white pt-5" id="contact"
style={{backgroundColor:'#10273c'}}>
<h2 style={{backgroundColor:'#10273c'}}
class='common-heading text-white'>
Explore your business with us
</h2>

<div className="container ">
<div className="form-section mx-auto">
<form ref={form} onSubmit={sendEmail} action="">
<div className="mb-3">
<div className="row">
<div className="col">
<label className="form-label" htmlFor="first-name">First name</label>
<input className="form-control" id="first-name" placeholder="First name"
aria-label="First name" type="text" required name="firstName" autoComplete='off' onChange={handleChange}/>
</div>
<div className="col">
<label htmlFor="last-name" className="form-label">Last name</label>
<input type="text" className="form-control" id="last-name" name="lastName"
placeholder="Last name" aria-label="Last name" required autoComplete='off' onChange={handleChange} />
</div>
</div>
</div>

<div className="mb-3">
<label htmlFor="email" className="form-label">Email address</label>
<input type="email" className="form-control" id="email" required name="email"
placeholder="Enter your email" aria-describedby="emailHelp" autoComplete='off' onChange={handleChange}/>
<div id="emailHelp" className="form-text text-white">We'll never share your email with anyone else.
</div>
</div>
<div className="mb-3">
<label htmlFor="phone" className="form-label" placeholder="Enter your phone number">Phone</label>
<input type="tel" className="form-control" id="phone" required name="phone" 
autoComplete='off' onChange={handleChange} />
</div>
<div className="mb-3">
<label htmlFor="message" className="form-label">Message</label>
<textarea className="form-control" id="message" required name="message"
placeholder="Enter your message " autoComplete='off' onChange={handleChange}></textarea>
</div>

<button type="submit" className="btn btn-primary" 
style={{backgroundColor:'#f3993c', border:'none'}}>
    Submit
</button>
</form>
</div>
</div>

</section>)}
</div>
   );
}

export default Contact;