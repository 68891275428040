import React from 'react';
import './about.css'
function About() {
  return (
    <div id="about">
        <section className='about-section'>
            <div className='about-section-inner-wrap'>
                <h2>Smart Automation for a <br/>Smarter Future</h2>
                <p>With <span style={{fontWeight:'bold'}}>creativity</span> at the heart of our approach, we are committed to <br/>
                  blending visual appeal and high performance in every one of our <br/>
                  <span style={{fontWeight:'bold'}}>material handling</span> and <span style={{fontWeight:'bold'}}>processing solutions</span>. We believe both are <br/>
                  essential to delivering excellence.</p>
            </div>
        </section>
    </div>
  )
}

export default About
