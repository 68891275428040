import React from 'react';
import './industries.css';

function Industries() {
  return (
    <div>
      <section className='ind-sec'>
        <div className="ind-container">
          <h2 className='headline'>Industries We Serve</h2>
          <div className='ind-block'>
            <div className="ind-block-container chemical">
              <h3>Chemicals</h3>
              <ul>
                <li>Agro Chemicals</li>
                <li>Fine Chemicals</li>
                <li>Bulk Chemicals</li>
              </ul>
            </div>

            <div className="ind-block-container food">
              <h3>Food</h3>
              <ul>
                <li>Chocolates</li>
                <li>Ready to eat</li>
                <li>Snack Food</li>
                <li>Spices</li>
                <li>Sugar</li>
                <li>Grains & Pulses</li>
              </ul>
            </div>

            <div className="ind-block-container pharma">
              <h3>Pharmaceutical</h3>
              <ul>
                <li>Formulations</li>
                <li>Active Pharmaceutical Ingredients (APIs)</li>
              </ul>
            </div>

            <div className="ind-block-container manufacturing">
              <h3>Manufacturing</h3>
              <ul>
                <li>Assembly Systems</li>
                <li>Vision-based Robotics</li>
                <li>Robotic Pick and Place</li>
              </ul>
            </div>

            <div className="ind-block-container extraction">
              <h3>Extraction</h3>
              <ul>
                <li>Supercritical Fluid Extraction (CO2 Extraction)</li>
                <li>Cryogenic Ethanol Extraction</li>
              </ul>
            </div>

            <div className="ind-block-container clean">
              <h3>Clean Technology</h3>
              <ul>
                <li>EV Battery Pack Assembly</li>
                <li>Root-End Machining for Wind Turbine Blades</li>
                <li>Catalytic Converter</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Industries;
