import React from 'react';

function Solution() {
    return (
        <div id="services">
            <div className="container text-center common-title fw-bold">
                <h2 className="common-heading">Add-Ons Services</h2>
                <hr className="w-25 mx-auto" />
            </div>

            <div className="container">
                <div className="row g-5">

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 fw-bolder mt-3">CNN-View<br />Digital performance monitoring tool</p>
                                <p>
                                    Deploy monitoring and analytics tools to track the performance of robotic/automation systems in
                                    real-time and analyze data on key performance indicators (KPIs) such as cycle time, weld/part
                                    quality, and equipment utilization to identify opportunities for optimization and efficiency gains.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">CNN-MT<br />Annual Service Package(Online and onsite services)</p>
                                <p>
                                    Offer ongoing technical support and maintenance services to ensure the smooth operation and
                                    uptime of robotic/automation systems. This can include remote monitoring, troubleshooting assistance,
                                    spare parts supply, and scheduled maintenance visits.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">CNN-UP<br />Upgrade HW/SW Packages of existing processes</p>
                                <p>
                                    Continuously optimize robotic/automation/manual processes, improve productivity, and enhance
                                    quality such as conduct process audits, identify areas for improvement, and implement upgrades
                                    or modifications to maximize efficiency and performance.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">Consultation and assessment</p>
                                <p>
                                    Understand specific needs, production requirements, and challenges such as assess
                                    the feasibility of robotic/automation solutions and provide expert advice on system
                                    selection, configuration, and integration.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">System design & engineering</p>
                                <p>
                                    Customized robotic/automation systems tailored to the end-user's requirements and production
                                    environment. This involves selecting appropriate robot models, welding/work cell equipment,
                                    peripherals, and integration with other machinery or systems.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">System integration, programming and commissioning</p>
                                <p>
                                    Installation and integration of robotic/automation systems into the end-user's facility.
                                    This includes mechanical and electrical installation, calibration, and testing to ensure seamless
                                    integration with existing workflows and processes.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">Training Programs and Education</p>
                                <p>
                                    Provides training programs for end-users' personnel to operate and maintain the robotic/automation
                                    system effectively. This includes training on robot programming, operation, safety procedures,
                                    troubleshooting, and preventive maintenance.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">Documentation and compliance</p>
                                <p>
                                    Help end-users ensure that robotic/automation processes meet relevant quality standards, regulations,
                                    and industry certifications. They implement quality control measures, conduct weld inspections, and
                                    provide documentation for traceability and compliance purposes.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-md-12">
                        <div className="px-3 py-2 shadow position-relative">
                            <div style={{ backgroundColor: '#f3993c' }}>
                                <p className="mb-1 mt-3 fw-bolder">Lifecycle Management</p>
                                <p>
                                    Assist end-users in managing the lifecycle of their robotic/automation systems,
                                    from initial deployment to eventual decommissioning or upgrades. This includes offering guidance
                                    on technology trends, equipment obsolescence, and long-term planning to ensure the continued
                                    success of robotic/automation operations.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Solution;
