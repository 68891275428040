import React from 'react'

function SocialMedia() {
  return (
    <div style={{backgroundColor:'#10273c'}}>
      
      <div class="main-footer-section">
        <div class="contact-details">

        <div class="footer-section py-5 text-white">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2 col-md-6 col-6">
                        <div class="footer-links">
                            <h4 class="text-white mt-5 mb-3">Contact</h4>
                            <hr class="conatiner mx-auto"/>
                            <ul class="text-light-grey list-unstyled d-flex flex-column gap-2">
                                <li><a href="#contact" className='text-decoration-none text-light'>Contact us</a></li>
                                <li>sales@cnn-robotics.com</li>
                                <li>Phone: +45 27 89 16 11</li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-6">
                        <div class="footer-links">
                            <h4 class="text-white mt-5 mb-3">About</h4>
                            <hr class="conatiner mx-auto"/>
                            <ul class="text-light-grey list-unstyled d-flex flex-column gap-2">
                                <li><a href='#about' className='text-decoration-none text-light'>About us</a></li>
                                <li><a href="#services" className='text-decoration-none text-light'>Services</a></li>
                                <li>CVR: 44711893</li>
                            </ul>
                        </div>
                    </div>

                    {/* currently hiding follow us
                     <div className="col-lg-2 col-md-6 col-6">
                        <div className="footer-links">
                            <h4 className="text-white mt-5 mb-3">follow us</h4>
                            <hr className="conatiner mx-auto"/>
                            <ul className="text-light-grey list-unstyled d-flex flex-column gap-2">
                                <li><i className="fa-brands fa-youtube"></i>  Youtube</li>
                                <li><i className="fa-brands fa-instagram"></i>  Instagram</li>
                                <li><i className="fa-brands fa-facebook"></i>  Facebook</li>
                                <li><i className="fa-brands fa-linkedin"></i>  LinkedIn</li>
                                <li><i className="fa-brands fa-twitter"></i>  Twitter</li>
                            </ul>
                        </div>
                    </div> */}

                    <div class="col-lg-12 col-md-12 ">
                        <hr class="conatiner mx-auto"/>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 ">
                                Copyright ©2024 All rights reserved.
                            </div>
                        </div>
                    </div>
                   
                    </div>
                </div>
            </div>
        </div>

    </div>

    </div>
  )
}

export default SocialMedia
