import './App.css';
import Home from './Home';
import About from './About';
import SocialMedia from './SocialMedia';
import Contact from './Contact';
import Solution from './Solution';
import Industries from './Industries';
import Navigation from './Navigation';
function App() {
  return (
    <div>
      <Navigation></Navigation>
      <Home></Home>
      <About></About>
      <Solution></Solution>
      <Industries></Industries>
      <Contact></Contact>
      <SocialMedia></SocialMedia>
    </div>
  );
}

export default App;
