import React from 'react';
import logo from './assets/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Navigation() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg pt-lg-5 sticky-top">
        <div className="container">
          <div className="navbar-brand text-white fw-bolder d-flex">
            <img src={logo} alt='logo' 
              style={{width:'150px', height:'50px'}}/>
            <h3 className='mt-3 ml-3'>Solution & Services</h3>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse fw-bolder" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-1 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize" href="#about">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize" href="#services">Solutions & Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
